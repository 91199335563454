import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/api';

export function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/', { replace: true });
  }, [navigate]);

  return null;
}
