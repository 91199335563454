import { useState, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { login, requestPasswordReset, signup } from '../../services/api';
import { Layout } from '../Layout';

interface LocationState {
  message: string | null;
}

interface AuthFormProps {
  isLogin: boolean;
  initialMsg: string | null;
  setIsAuthenticated: (value: boolean) => void;
}

export function AuthForm({ isLogin, setIsAuthenticated }: AuthFormProps) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
    
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      if (isLogin) {
        const response = await login({ email, password });
        localStorage.setItem('authToken', response.token);
        setIsAuthenticated(true);
        navigate('/subscription');
      } else {
        await signup({ email });
        navigate('/verify-prompt', { state: { email } });
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setError(null);
    setMessage(null);

    // Validate email
    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      await requestPasswordReset({ email: email.trim() });
      setMessage('Check your email for a password reset link');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    }
  };

  return (
    <Layout>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2 className="text-center mb-4">
            {isLogin ? 'Sign in' : 'Create Your Account'}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="email"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
            {isLogin && (
              <>
                <div className="mb-3">
                  <input
                    type="password"
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                    required
                  />
                </div>
              </>
            )}
            <button 
              type="submit" 
              className="btn btn-primary w-100" 
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              ) : null}
              {isLogin ? 'Login' : 'Continue with Email'}
            </button>
            <div className="text-center mt-3">
              <a href={isLogin ? '/signup' : '/login'} className="text-decoration-none">
                {isLogin ? "Don't have an account? Sign up" : 'Already have an account? Login'}
              </a>
              {isLogin && (
                <>
                  <span className="mx-2">•</span>
                  <a 
                    href="#" 
                    className="text-decoration-none"
                    onClick={handleForgotPassword}
                  >
                    Forgot password?
                  </a>
                </>
              )}
            </div>
            {error && (
              <div className="text-center mt-3">
                <div className="alert alert-danger py-2" role="alert">
                  {error}
                </div>
              </div>
            )}
            
            {message && (
              <div className="text-center mt-3">
                <div className="alert alert-success py-2" role="alert">
                  {message}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
}
