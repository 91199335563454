import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="card shadow" style={{ width: '800px', minHeight: '400px' }}>
        <div className="card-body p-0">
          <div className="px-4 pt-4 text-center">
            <img 
              src="/birdie_logo.svg" 
              alt="Company Logo" 
              style={{ height: '40px' }}
            />
          </div>
          <div className="p-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
