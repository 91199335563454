import { useState, FormEvent } from 'react';
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom';
import { setPassword } from '../../services/api';
import { Layout } from '../Layout';

export function SetPasswordForm({ isReset, setIsAuthenticated }: { isReset: boolean, setIsAuthenticated: (value: boolean) => void }) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  
  const [password, setPasswordValue] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  if (!token) {
    return <Navigate to="/signup" replace />;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccessMessage(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    setIsLoading(true);

    try {
      const data = await setPassword({ token, password });
      localStorage.setItem('authToken', data.token);
      setIsAuthenticated(true);
      if (isReset) {
        navigate('/login', { state: { message: 'Password reset successful' } });
      } else {
        navigate('/subscription');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h2 className="text-center mb-4">{isReset ? 'Reset your password' : 'Create your password'}</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="password"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder="Password"
                value={password}
                onChange={(e) => setPasswordValue(e.target.value)}
                disabled={isLoading}
                required
                minLength={8}
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
            {error && (
              <div className="alert alert-danger py-2" role="alert">
                {error}
              </div>
            )}
            {successMessage && (
              <div className="alert alert-success py-2" role="alert">
                {successMessage}
              </div>
            )}
            <button 
              type="submit" 
              className="btn btn-primary w-100" 
              disabled={successMessage !== null}
            >
              {isLoading ? (
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              ) : null}
              Set Password
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
