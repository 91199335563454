import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { checkAuth } from './services/api';
import { AuthForm } from './components/Auth/AuthForm';
import { VerifyPrompt } from './components/Auth/VerifyPrompt';
import { SetPasswordForm } from './components/Auth/SetPasswordForm';
import { ManageSubscription } from './components/Subscription/ManageSubscription';
import { Logout } from './components/Auth/Logout';

function App(): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const { authenticated } = await checkAuth();
        setIsAuthenticated(authenticated);
      } catch (error) {
        setIsAuthenticated(false);
      } 
    };
    verifyAuth();
    console.log('isAuthenticated', isAuthenticated);
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route 
            path="/login" 
            element={<AuthForm isLogin={true} initialMsg={null} setIsAuthenticated={setIsAuthenticated} />} 
          />
          <Route 
            path="/signup" 
            element={<AuthForm isLogin={false} initialMsg={null} setIsAuthenticated={setIsAuthenticated} />} 
          />
          <Route 
            path="/" 
            element={<Navigate to="/signup" />} 
          />
          <Route 
            path="/verify-prompt" 
            element={<VerifyPrompt />} 
          />
          <Route 
            path="/verify-email" 
            element={<SetPasswordForm isReset={false} setIsAuthenticated={setIsAuthenticated} />} 
          />
          <Route 
            path="/reset-password" 
            element={<SetPasswordForm isReset={true} setIsAuthenticated={setIsAuthenticated} />} 
          />
          <Route 
            path="/subscription" 
            element={<ManageSubscription />} 
          />
          <Route 
            path="/logout" 
            element={<Logout />} 
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
