import { useState, useEffect } from 'react';
import { createCheckoutSession, checkSubscription } from '../../services/api';
import { Layout } from '../Layout';

const CHROME_STORE_URL = "https://chrome.google.com/webstore/detail/birdie"; 
const HELP_CENTER_URL = "https://birdie.helpscoutdocs.com/"; 

export function ManageSubscription() {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(true);

  useEffect(() => {
    const checkUserSubscription = async () => {
      try {
        const { hasSubscription } = await checkSubscription();
        setHasSubscription(hasSubscription);
      } catch (err) {
        console.error('Failed to check subscription status:', err);
      } finally {
        setIsChecking(false);
      }
    };

    checkUserSubscription();
  }, []);

  const handleActivate = async () => {
    try {
      setIsLoading(true);
      const { url } = await createCheckoutSession();
      window.open(url, '_blank');
    } catch (err) {
      setError('Failed to start subscription process');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isChecking) {
    return (
      <Layout>
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Layout>
    );
  }

  if (!hasSubscription) {
    return (
      <Layout>
        <div className="text-center">
          <h2 className="mb-3">Activate your License</h2>
          <p className="mb-4">Start your 1 month trial to Birdie. Cancel anytime.</p>

          {error && (
            <div className="alert alert-danger py-2 mb-4" role="alert">
              {error}
            </div>
          )}

          <button 
            onClick={handleActivate}
            disabled={isLoading}
            className="btn btn-primary w-100"
          >
            {isLoading ? (
              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            ) : null}
            Activate
          </button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="row">
        <div className="col-md-6 text-center p-4">
          <h2 className="mb-3">Your license is active</h2>
          <p>Follow the instructions to get started!</p>
        </div>
        <div className="col-md-6 p-4">
          <ol className="mb-4">
            <li className="mb-2">Install the Birdie Chrome Extension</li>
            <li className="mb-2">Log in with your email and password</li>
            <li className="mb-2">Configure your settings</li>
            <li className="mb-2">Start prospecting on LinkedIn</li>
          </ol>
          <div className="d-flex align-items-center justify-content-between p-4">
            <a href={HELP_CENTER_URL} className="text-decoration-none" target="_blank" rel="noopener noreferrer">
              Help Center
            </a>
            <a 
              href={CHROME_STORE_URL} 
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Install Birdie
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}