import { useLocation, Navigate, Link } from 'react-router-dom';
import { Layout } from '../Layout';

interface LocationState {
  email: string;
}

export function VerifyPrompt() {
  const location = useLocation();
  const state = location.state as LocationState;

  if (!state?.email) {
    return <Navigate to="/signup" replace />;
  }

  return (
    <Layout>
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <h2 className="mb-4">Verify your email</h2>
          <p>
            We've sent an email to <strong>{state.email}</strong>. Click on the link to verify your email.
          </p>
          <div className="mt-4">
            <Link to="/signup" className="btn btn-link text-decoration-none">
              Wrong email?
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
