import { API_BASE_URL } from '../config';

interface AuthResponse {
  token: string;
  error?: string;
}

interface AuthRequest {
  email: string;
  password: string;
}

export interface SubscriptionStatus {
  hasSubscription: boolean;
  planName?: string;
  status?: string;
  currentPeriodEnd?: number;
}

interface CheckoutSessionResponse {
  url: string;
}

interface AuthCheckResponse {
  authenticated: boolean;
  user?: {
    id: string;
    email: string;
  };
}

interface SignupEmailRequest {
  email: string;
}

interface SetPasswordRequest {
  token: string;
  password: string;
}

interface PasswordResetRequest {
  email: string;
}

interface PasswordResetResponse {
  message: string;
}

export async function login(credentials: AuthRequest): Promise<AuthResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Login failed');
    }

    return data;
  } catch (error) {
    throw error;
  }
}

export async function signup(credentials: SignupEmailRequest): Promise<void> {
  const response = await fetch(`${API_BASE_URL}/api/user/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.error || 'Signup failed');
  }
}

export async function setPassword(request: SetPasswordRequest): Promise<AuthResponse> {
  const response = await fetch(`${API_BASE_URL}/api/user/set-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || 'Failed to set password');
  }

  return data;
}

export async function checkSubscription(): Promise<SubscriptionStatus> {
  const token = localStorage.getItem('authToken');
  
  const response = await fetch(`${API_BASE_URL}/api/subscription/check`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to check subscription status');
  }

  return response.json();
}

export async function createCheckoutSession(): Promise<CheckoutSessionResponse> {
  const token = localStorage.getItem('authToken');
  
  const response = await fetch(`${API_BASE_URL}/api/subscription/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to create checkout session');
  }

  return response.json();
}

export async function getManageSubscriptionUrl(): Promise<CheckoutSessionResponse> {
  const token = localStorage.getItem('authToken');
  
  const response = await fetch(`${API_BASE_URL}/api/subscription/billing-portal`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || 'Failed to get billing portal URL');
  }

  return response.json();
}

export async function checkAuth(): Promise<AuthCheckResponse> {
  const token = localStorage.getItem('authToken');
  
  try {
    const response = await fetch(`${API_BASE_URL}/api/user/check-auth`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return { authenticated: false };
    }

    return await response.json();
  } catch (error) {
    return { authenticated: false };
  }
}

export const requestPasswordReset = async (request: PasswordResetRequest): Promise<PasswordResetResponse> => {
  const response = await fetch(`${API_BASE_URL}/api/user/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to request password reset');
  }

  return response.json();
};

export function logout(): void {
  localStorage.removeItem('authToken');
}
